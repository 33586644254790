import { Link, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import { ReactComponent as Logo } from '../assets/design/logo-alt.svg';
import window from '../assets/images/window-1.jpg';
import Guarantee from '../components/guarantee';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Service from '../components/service';
import StyledButton from '../components/styledButton';
import '../css/markdown.css';

const CityFuneral = ({ data }) => {
  const city = data.city;
  const name = city.frontmatter.title;
  const services = data.services.edges;

  return (
    <Layout
      headerClass='relative bg-white'
      bodyClass='px-0 md:px-0 lg:px-0'
      hasHero={false}
      heroImage={window}
    >
      <SEO
        title={`${name} Funeral Home`}
        description={`City of Oaks Funeral Home and Cremation serving ${name} with traditional funerals and affordable alternatives.`}
      />

      <div className='flex flex-col mt-6'>
        <div className='w-24 h-24 self-center'>
          <Logo />
        </div>
        <div className='text-center'>
          <h2 className='text-xl md:text-2xl uppercase text-gray-700'>
            City of Oaks Funeral Home & Cremations
          </h2>
        </div>
      </div>

      <div className='container mx-auto px-6 md:px-10 lg:px-24 pt-8 '>
        <div className='flex flex-col'>
          {/* <div dangerouslySetInnerHTML={{ __html: city.html }}></div> */}
          <div className='font-serif font-hairline'>
            <h1 className='text-3xl md:text-4xl text-green-700'>
              Affordable full-service funeral home serving {name}
            </h1>
          </div>
          <p className='py-6 text-lg'>
            We're an affordable, full-service affordable funeral home serving{' '}
            {name} and beyond. Simply having to reach out to a funeral home can
            feel overwhelming. Our goal is to simplify the entire funeral home
            experience and make this process as easy as possible. We hope to
            eliminate any unnecessary surprises by publishing our total cost and
            the services we provide directly on our website. Hopefully this
            simple gesture helps provide your family with a little piece of
            mind. Other funeral homes charge more but we're committed to
            providing {name} residents with exceptional service at a reasonable
            price. We're located in Wake County, North Carolina, but we provide
            our services to families throughout the entire state and beyond.
            Every year we help dozens of families in {name} make simple,
            affordable funeral arrangements.
          </p>
          <p className='text-xl pb-8'>
            We assist families all throughout North Carolina and we do{' '}
            <strong>not</strong> charge an additional mileage fee.
          </p>
          <div className='self-center my-3'>
            <Link to='/services/'>
              <StyledButton>Talk to us</StyledButton>
            </Link>
          </div>
          <div className='self-center my-3'>
            <Link to='/services/'>
              <StyledButton>Find out more</StyledButton>
            </Link>
          </div>
          {/* re-link block */}
          <div className='flex flex-wrap justify-center items-center my-6'>
            <div className='self-center flex-col text-center items-center w-full my-2 lg:w-1/3'>
              <Link
                to={`/city/${name
                  .replace(/\s+/g, '')
                  .toLowerCase()}/cremation/`}
              >
                <StyledButton>Direct cremation in {name}</StyledButton>
              </Link>
            </div>
            <div className='self-center flex-col text-center items-center w-full my-2 lg:w-1/3'>
              <Link to={`/services/`}>
                <StyledButton>View all of our services</StyledButton>
              </Link>
            </div>
            <div className='self-center flex-col text-center items-center w-full my-2 lg:w-1/3'>
              <Link
                to={`/city/${name.replace(/\s+/g, '').toLowerCase()}/burial/`}
              >
                <StyledButton>Immediate burial in {name}</StyledButton>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-wrap justify-center items-stretch -mx-2'>
        <p className='mb-2 text-4xl text-gray-800 self-center'>Our Services</p>
        <div className='flex flex-wrap justify-center items-stretch -mx-2'>
          {services.map(({ node }) => (
            <Service
              title={node.frontmatter.title}
              slug={node.frontmatter.slug}
              url={node.frontmatter.path}
              key={node.frontmatter.path}
              icon={node.frontmatter.icon}
              price={node.frontmatter.price}
              blurb={node.frontmatter.blurb}
            ></Service>
          ))}
        </div>

        <Guarantee />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String) {
    city: markdownRemark(id: { eq: $id }) {
      frontmatter {
        path
        title
      }
    }
    services: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/services/" } }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            path
            icon
            price
            blurb
          }
          excerpt
        }
      }
    }
  }
`;
export default CityFuneral;
